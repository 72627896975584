import React, { useRef } from "react"
import * as hiStyles from "./heroImage.module.css"
import { GatsbyImage } from "gatsby-plugin-image"

const HeroImage = React.forwardRef((props, ref) => {

  const posterImgRef = useRef()

  return (
    <div ref={ref} className={hiStyles.background}>
      <div ref={posterImgRef}>        
        <GatsbyImage 
          image={props.bgImage} alt="HALLO" 
          placeholder="blurred"
          objectFit="contain"
          objectPosition="center center"
        />
      </div>
    </div>
  )
})

export default HeroImage
import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import HeroImage from "../../heroImage"

import * as bhStyles from "../bigheader.module.css"

const BigHeaderImage = React.forwardRef((props, ref) => {
  const tl = useRef()
  const mediaRef = useRef()
  const textRef = useRef()

  const backgroundStyle = {
    backgroundImage: `${props.bgImage ? `url(${props.bgImage})` : 'none'}`,
    backgroundColor: `${props.bgCol ? `${props.bgCol}` : 'none'}`,
    width: "100vw",
    height: "100vh",
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }

    // starte mit grossem bild
    if (props.mode === "mediaFirst") {
      tl.current = gsap
        .timeline({
          scrollTrigger: {
            id: "bigheaderScrollTrigger",
            trigger: "#bigheaderWrap",
            pin: true, // pin the trigger element while active
            start: "top top", // when the top of the trigger hits the top of the viewport
            end: "bottom 20%", // end after scrolling 500px beyond the start
            scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            markers: false,
            snap: {
              snapTo: "labels", // snap to the closest label in the timeline
              duration: { min: 0.2, max: 1 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
              delay: 1, // wait 0.2 seconds from the last scroll event before doing the snapping
              ease: "power1.inOut", // the ease of the snap animation ("power3" by default)
            }
          },
        })
        .addLabel("move")
        .from(textRef.current, { y: "+=50", opacity: "0" })
        .to(
          mediaRef.current,
          {
            scale: "0.8",
            opacity: "0.3",
            filter: "grayscale(1)",
            transformOrigin: "right bottom",
          },
          "<"
        )
        .addLabel("end")
    }

    //starte mit dem text
    if (props.mode === "textFirst") {
      
      gsap.set(mediaRef.current, { scale: "1.1", x: "20%", top: "20%", opacity: "1", filter: "blur(0px)", transformOrigin: "top top" })

      tl.current = gsap
        .timeline({
          scrollTrigger: {
            id: "bigheaderScrollTrigger",
            trigger: "#bigheaderWrap",
            pin: true, // pin the trigger element while active
            start: "top top", // when the top of the trigger hits the top of the viewport
            end: "bottom 20%", // end after scrolling 500px beyond the start
            scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            markers: false,
            snap: {
              snapTo: "labels", // snap to the closest label in the timeline
              duration: { min: 0.2, max: 1 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
              delay: 1, // wait 0.2 seconds from the last scroll event before doing the snapping
              ease: "power1.inOut", // the ease of the snap animation ("power3" by default)
            }
          },
        })
        .addLabel("move")
        .to(textRef.current,
          {
            y: "+=50",
            opacity: "0"
          })
        .to(
          mediaRef.current,
          {
            scale: "1",
            x: "0",
            top: "0",
            opacity: "1",
            filter: "blur(0px)",
            transformOrigin: "top top",
          },
          "<"
        )
        .addLabel("end")
    }

    //text und bild zusammen
    if (props.mode === "allatonce") {
      gsap.set(textRef.current, { y:"+=50", opacity: "0" })

      tl.current = gsap
        .timeline({})
        .addLabel("move")
        .to(textRef.current,
          {
            y: "-=50",
            opacity: "1",
            duration: "1"
          })
        .addLabel("end")
    }    

  }, [])

  return (
    <div id="bigheaderWrap" className={bhStyles.bigheaderWrap} style={backgroundStyle}>

      <HeroImage ref={mediaRef} bgCol={props.bgCol} bgImage={props.bgImage} />

      {/* TEXT */}
      <div ref={textRef} className={`container-fluid ${bhStyles.textDivWrapper}`}>

        {props.children}

      </div>
    </div>
  )
})

// Specifies the default values for props:
BigHeaderImage.defaultProps = {
  mode: 'mediaFirst' //textFirst
};

export default BigHeaderImage
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderImage from "../../../components/header/bigHeader/bigHeaderImage"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logos/logo-abb.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import Specs from "../../../components/specs"
import YtVideo from "../../../components/ytvideo"
import * as projekteStyles from "../projekte.module.css"


const ABBBuildingsEcosystem = function (props, ref) {

    const data = useStaticQuery(graphql`
        query ABBBuildingsEcosystemQuery {
            heropic: file(relativePath: { eq: "images/projekte/abb-mybuildings/thumbleiste/abb-be-02.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/abb-mybuildings/thumbleiste/abb-mybuildings-01.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/abb-mybuildings/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }
        }
    `)
  
    const specsData = [
        { title: "Eckdaten", objects: [logo, "Kundenportal"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["sketch", "html", "css", "js", "react"],
        },
      ]

    return (
        <Layout>
            <SEO
                title="ABB Ability™ Building Ecosystem"
                description="Q:marketing realisiert Kundenportal für ABB."
                image={data?.ogimage?.childImageSharp?.fixed}
            />
   
            <BigHeaderVideo mode="mediaFirst" className={`${projekteStyles.child}`} bgVideo="W4US9w_fiTM" posterImage="">
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" />
                        <h1>ABB Ability™ Building Ecosystem</h1>
                        <p className="introtext">Die gemeinsam mit internationalen Partnern entwickelte Plattform vereint verschiedene digitale Mehrwertlösungen für die intelligente Gebäudesteuerung.</p>
                        <AnchorLink
                            to="/projekte/ecosystems/abb-buildings-ecosystem#thumbleiste"
                            title="↓ Mehr Erfahren"
                            className="q-btn q-btn-primary"
                        />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>            
            {/* <BigHeaderImage mode="textFirst" bgCol="#fff" bgImage={data.heropic.childImageSharp.gatsbyImageData}>
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-4">
                        <img src={logo} className="h-40px" alt="" />
                        <h1><span className="lineBackgroundWhite">ABB Ability™ Building Ecosystem</span></h1>
                        <p className="introtext lineBackgroundWhite py-2">Die gemeinsam mit internationalen Partnern entwickelte Plattform vereint verschiedene digitale Mehrwertlösungen für die intelligente Gebäudesteuerung.</p>
                        <AnchorLink to="/projekte/ecosystems/abb-buildings-ecosystem#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                </div>
            </BigHeaderImage> */}
                               
            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>   


            {/* Video */}
            {/* <ListenModule headline="Produktvideo">
                <div className="row">
                    <YtVideo videoId="W4US9w_fiTM"/>                
                </div>
            </ListenModule>             */}


            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Offene und skalierbare digitale Plattform für die Steuerung intelligenter Gebäudetechnik in modernen Bürogebäuden und Hotels.",
                        "Ermöglicht optimierte Flächennutzung, Energieeffizienz und nachhaltige Gebäudenutzung.",
                        "Entwickelt in einem Netzwerk internationaler IT-Dienstleister und Entwicklungsabteilungen unseres Kunden ABB.",
                    ]}
                />
            </ListenModule>
                
            
            <Specs data={specsData} />
                                
            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="juergen-dworak"/>

        </Layout>
    )
}

export default ABBBuildingsEcosystem